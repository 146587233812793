<template>
	<div v-if="isAdmin">
		<f-card>
			<v-row>
				<v-col cols="6">
					<v-select
						v-model="pos.distributorID"
						outlined
						dense
						data-testid="pos-distributor-select"
						hide-details="auto"
						:items="distributors"
						item-text="name"
						item-value="id"
						:label="$t('labels.distributor')" />
				</v-col>
			</v-row>
		</f-card>
		<f-card-spacer />
	</div>
</template>

<script>
export default {
	data: () => ({
		distributors: [],
		isAdmin: false,
	}),
	computed: {
		pos() {
			return this.$store.state.pos.view;
		},
	},
	watch: {
		"pos.distributorID": {
			handler(value, oldValue) {
        const res = this.distributors.find(d => d.id === value);
        if (res) {
          this.$store.state.distributor.view = res;
					this.$store.dispatch("distributor/refresh");
        }
			},
			deep: true,
		},
	},
	created() {
		this.checkAdmin();
	},
	methods: {
		checkAdmin() {
			const user = this.$store.state.auth.user;
			if (user.distributorID) {
				this.isAdmin = false;

				this.pos.distributorID = user.distributorID;
			} else {
				this.isAdmin = true;
				this.getData();
			}
		},
		async getData() {
			const distributors = await this.$store.dispatch("distributor/list");
			this.distributors = distributors;
		},
	},
};
</script>
