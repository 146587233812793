export default {
	data: () => ({
		// file: null
		errors: [],
		expandedHelp: false,
		distheaders: [],
		goToTotals: false,
	}),
	computed: {
		file() {
			return this.$store.state.pos.view.file;
		},
		extras() {
			return this.$store.state.pos.view.extras;
		},
		loading() {
			return !this.$store.state.system.loading.done;
		},
		notRequiredLabel() {
			return this.$t("validations.not_required");
		},
	},
	created() {
		this.$store.dispatch("system/loading", "done");
		if (this.$route.name == "pos-upload-process") {
			this.checkFile(null, false);
		}
	},
	methods: {
		noSellsReport() {
			this.$router.push({
				name: "pos-nosells",
			});
		},
		pickFile() {
			this.$refs.inputfile.click();
		},
		async checkFile(e, getFile = true) {
			if (getFile) {
				const file = e.target.files[0];
				this.$store.state.pos.view.file = file;
			}

			this.$store.dispatch("system/loading", "data");
			const headers = await this.$store.dispatch("pos/checkHeadersConfig");
			this.$store.dispatch("system/loading", "done");

			this.distheaders = headers;
			if (headers) {
				this.errors = [];
				this.$store.state.pos.view.config.headers = headers;
				this.$store.dispatch("system/loading", "data");
				const result = await this.$store.dispatch(
					"pos/dataProccess",
					this.file
				);
				this.$store.dispatch("system/loading", "done");

				if (result.status) {
					if (this.extras.missingPrices || this.extras.missingDates) {
						this.goToTotals = true;
					}
					this.nextStep();
				} else {
					this.$store.state.pos.view.file = null;
					if (getFile) {
						e.target.value = "";
					}

					this.expandedHelp = true;
					if (result.message == "NO_FILE") {
						this.$router.push({
							name: "pos-upload",
						});
					} else if (result.message == "DATA_FAIL") {
						this.errors.push({
							type: "error",
							msg: this.$t("pages.pos.new.error.dataFail"),
						});
					} else if (result.message == "REQUIRED_HEADERS_NOT_FOUND") {
						this.errors.push({
							type: "error",
							msg: this.$t("pages.pos.new.error.requiredHeadersNotFoud"),
						});
					} else if (result.message == "INSUFFICIENT_NUMBER_OF_COLUMNS") {
						this.errors.push({
							type: "error",
							msg: this.$t("pages.pos.new.error.insufficientNumberOfColumns"),
						});
					} else if (result.message == "DATA_FAIL") {
						if (result.errors["inPartNumberColumn"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.inPartNumberColumn"),
							});
						}
						if (result.errors["invalidPartNumberColumn"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.invalidPartNumberColumn"),
							});
						}
						if (result.errors["invalidPartNumberType"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.invalidPartNumberType"),
							});
						}
						if (result.errors["incnaeColumn"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.incnaeColumn"),
							});
						}
						if (result.errors["inCityColumn"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.inCityColumn"),
							});
						}
						if (result.errors["inProviderColumn"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.inProviderColumn"),
							});
						}
						if (result.errors["inStateColumn"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.inStateColumn"),
							});
						}
						if (result.errors["inDateColumn"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.inDateColumn"),
							});
						}
						if (result.errors["invalidDateColumn"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.invalidDateColumn"),
							});
						}
						if (result.errors["inQuantityColumn"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.inQuantityColumn"),
							});
						}
						if (result.errors["invalidQuantityColumn"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.invalidQuantityColumn"),
							});
						}
						if (result.errors["inDistributorCNPJColumn"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.inDistributorIdColumn"),
							});
						}
						if (result.errors["inDistributorCNPJNumber"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.inDistributorIdNumber"),
							});
						}
						if (result.errors["inDistributorNameColumn"]) {
							this.errors.push({
								type: "error",
								msg: this.$t("pages.pos.new.error.inDistributorNameColumn"),
							});
						}
						// console.log( result.errors )
					}
					// console.log( result )
				}
			} else {
				this.setConfig();
			}
		},
		setConfig() {
			this.$router.push({
				name: "pos-set-config",
			});
		},
		nextStep() {
			// var goToTotals = false
			// const totals = this.$store.state.pos.view.totals
			// for( var prop in this.$store.state.pos.view.totals ){
			//     if( !totals[prop] ){
			//         goToTotals = true
			//     }
			// }

			if (this.goToTotals) {
				this.$router.push({
					name: "pos-missing-data",
				});
			} else {
				this.$router.push({
					name: "pos-preview",
				});
			}
		},
	},
};
